/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { UserProvider } from '../_dependencies/user-provider';
import { Identity } from '../_models/user/Identity';
import { AccountCompletion, UserResponse, UserVerificationResponse } from '../_models/user/Registration';
import { CompanyRole, User, UserAssignment } from '../_models/user/User';
import { HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import * as _ from 'underscore';
import { FrontendInfo, PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { BackendInfo } from '../_models/api/BackendInfo';
import { Company } from '../_models/configuration/Company';
import { Reviewer } from '../_models/tasks/Reviewer';
import { AllowedDownloadFormat, Class, ClassValue, CompanyStorageUsage } from '../public_api';
import { ConnectionService } from './connection.service';
import { EventHubService } from './event-hub.service';
import { HubspotLogin } from './logging.service';
import { TranslationService } from './translation.service';
import { UserDevice } from '../_models/user/UserDevice';
import { PagedResponse } from '../_models/api/PagedResponse';


const GIGABYTE = 1024 * 1024 * 1024;
const MEGABYTE = 1024 * 1024;
const KILOBYTE = 1024;
const MISSING_APPROVAL_ERROR = 'MissingApproval';

export class SignIn
{
  username: string;
  password: string;
  reviewerId: string;
  userDeviceId?: string;
  frontendName?: string;
}

export interface Progress
{
  speedKBps: number;
  speedMBps: number;
  loadedKB: number;
  loadedMB: number;
  totalKB: number;
  totalMB: number;
  percentage: number;
  cancelAction: () => void;
}

export interface PasswordValidStatus
{
  validLength?: boolean;
  validNoSpaces?: boolean;
  validCapitalLetter?: boolean;
  validLowerCase?: boolean;
  validNumber?: boolean;
  validSpecialChar?: boolean;
}

interface DemoLogin
{
  user: User;
  companyId: string;
}

interface RegisterCompanyLoginRequest
{
  companyId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService
{
  private user: User;
  private company: Company;
  private role: CompanyRole;
  private reviewer: Reviewer;
  private userDevice: UserDevice;

  assignments: UserAssignment[];
  initPromise: Promise<void>;
  clsValuesPromise: Promise<void>;
  refreshPromise: Promise<void> | null = null;
  identityChanged: EventEmitter<Identity> = new EventEmitter<Identity>();
  usersApprovalMissing: EventEmitter<void> = new EventEmitter<void>();
  refreshTokenExpired: EventEmitter<void> = new EventEmitter<void>();
  logHubspotSignInEvent: EventEmitter<HubspotLogin> = new EventEmitter<HubspotLogin>();
  frontendInfo: FrontendInfo;

  constructor(
    @Inject('UserProvider') private userProvider: UserProvider,
    @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
    private translationService: TranslationService,
    public connectionService: ConnectionService,
    private eventHubService: EventHubService)
  {
    this.eventHubService.companyChanged.subscribe(c =>
    {
      if (this.company && c && this.company.id === c.id)
      {
        this.selectCompany(c);
      }
    });
    this.translationService.languageChanged.subscribe(() => this.languageChanged());
    this.initPromise = this.init();
  }

  async waitForInit()
  {
    if (this.initPromise)
    {
      await this.initPromise;
    }
  }

  async waitForClsValues()
  {
    if (this.clsValuesPromise)
    {
      await this.clsValuesPromise;
    }
  }

  private async init(): Promise<void>
  {
    try
    {
      this.frontendInfo = await this.platformInfoProvider.getFrontendInfo();
      this.user = await this.userProvider.getCurrentUser();
      this.userDevice = await this.userProvider.getUserDevice();
      const serverOnline = await this.testAnonymous();
      if (this.user)
      {
        if (!serverOnline)
        {
          // so the server is not available, perhaps it's just temporary
          // ==> no need to delete the known user infos
          this.user = undefined;
          return;
        }
        const userResponse = await this.renewLogin();
        await this.userProvider.storeUser(userResponse.user);
        const assignments = await this.getUserAssignments();
        const lastCompId = await this.userProvider.getLastCompanyId();
        if (this.frontendInfo.frontendName === 'ILST')
        {
          this.assignments = _.filter(assignments, a => a.role > CompanyRole.stakeholder);
        }
        else
        {
          this.assignments = _.filter(assignments, a => a.role > CompanyRole.none);
        }
        await this.chooseDefaultCompany(lastCompId);
        if (userResponse.error && userResponse.error === MISSING_APPROVAL_ERROR)
        {
          this.usersApprovalMissing.emit();
        }
      }
      else if (this.frontendInfo.frontendName !== 'ILST')
      {
        const reviewer = await this.userProvider.getLastReviewer();
        const reviewerId = (reviewer) ? reviewer.id : this.reviewersUrlId();
        const userResponse = await this.signInReviewer(reviewerId);
        if (userResponse.error && userResponse.error === MISSING_APPROVAL_ERROR)
        {
          this.usersApprovalMissing.emit();
        }
      }
    }
    catch (err)
    {
      console.log(`Error initializing authService (${JSON.stringify(err)})`);
      this.user = undefined;
      await this.userProvider.storeUser(undefined);
      this.userDevice = undefined;
      await this.userProvider.storeUserDevice(undefined);
      this.assignments = [];
      this.initPromise = null;
      this.emit();
    }
    finally
    {
      const environment = (await this.platformInfoProvider.getFrontendInfo()).frontendName;
      if (environment === 'WEB')
      {
        this.loginToHubSpot();
      }
      this.initPromise = null;
    }
  }

  isChatUrl()
  {
    const urlPath = window.location.href;
    return urlPath.indexOf('chat') >= 0;
  }

  reviewersUrlId(): string
  {
    const urlParts = window.location.href.split('/');
    const reviewerIndex = urlParts.indexOf('reviewer');
    if (reviewerIndex >= 0)
    {
      const id = urlParts[reviewerIndex + 1];
      return id;
    }
    return undefined;
  }

  handleSupportRequestFromExtension(): boolean
  {
    let hubspotData = window.location.href;
    hubspotData = hubspotData.toLowerCase().replace(/%2f/gi, '/');
    const supportUrl = hubspotData.toLowerCase().split('/opensupport/', 2);
    if (supportUrl !== undefined && supportUrl.length > 1)
    {
      try
      {
        const data = supportUrl[1].split('/', 2);
        const email = data[0];
        const userName = data[1];
        if (email !== 'noemail')
        {
          this.logHubspotSignInEvent.emit(
            {
              user : {email: email, username: userName},
              companyName : this.company ? this.company.name : ''
            });
        }
      }
      catch (err)
      {
        console.log('Open support from extension error.');
      }
    }

    return hubspotData && hubspotData.toLowerCase().includes('/opensupport/');
  }

  loginToHubSpot()
  {
    this.addHubSpotCode();
    if (this.user)
    {
      setTimeout(() =>
      {
        this.logHubspotSignInEvent.emit({ user : this.user, companyName : this.company ? this.company.name : ''});
      }, 2500);
    }
  }

  /**
   * Add HubSpot Embed Code
   */
  addHubSpotCode()
  {
    try
    {
      let hubSpotCode = <HTMLScriptElement>document.getElementById('hs-script-loader');
      if (!hubSpotCode || hubSpotCode === undefined)
      {
        const head = document.getElementsByTagName('head')[0];
        hubSpotCode = document.createElement('script');
        hubSpotCode.id = 'hs-script-loader';
        hubSpotCode.type = 'text/javascript';
        hubSpotCode.src = 'https://js.hs-scripts.com/6414078.js';
        head.appendChild(hubSpotCode);
      }
      // @ts-ignore
      if (window.HubSpotConversations)
      {
        this.eventHubService.loadHubSpotSupportWidget.emit();
      }
      else
      {
        // @ts-ignore
        window.hsConversationsOnReady = [() =>
        {
          // Script ready to be used ... now emit loaded event
          this.eventHubService.loadHubSpotSupportWidget.emit();
          // @ts-ignore
          window.HubSpotConversations.widget.close();
          // @ts-ignore
          window.HubSpotConversations.on('unreadConversationCountChanged', payload =>
          {
            this.eventHubService.hubspotUnreadMessages.emit(payload.unreadCount);
          });
          this.eventHubService.hubspotWidgedOpenStatus.emit(false);
          if (this.handleSupportRequestFromExtension() || this.isChatUrl())
          {
            setTimeout(() =>
            {
              // @ts-ignore
              window.location.href.includes('/web/')
                ? this.eventHubService.toggleSupport.emit()
                : (<any>window).HubSpotConversations.widget.open();
            }, 2000);
          }
        }];
      }
    }
    catch (err)
    {
      console.log('HUBSPOT Error adding code');
      console.log(err);
    }
  }

  getHubSpotLoginInfo()
  {
    return { email : this.user ? this.user.email : 'noEmail', username : this.user ? this.user.username : 'noUserName'};
  }


  /**
   * Reselects the same company, to update the configuration from the server (GetFullCompany will be called)
   */
  async refreshCompany()
  {
    if (!this.company)
    {
      return;
    }
    await this.selectCompany(this.company);
  }

  async updateCompany()
  {
    if (this.company)
    {
      this.company = await this.getFullCompany(this.company.id);
    }
  }

  private async chooseDefaultCompany(lastCompanyId: string)
  {
    let lastCompany: Company = null;
    if (lastCompanyId)
    {
      const x = _.find(this.assignments, a => a.companyId === lastCompanyId && a.role > CompanyRole.none);
      if (x)
      {
        lastCompany = x.company;
      }
    }
    if (lastCompany)
    {
      await this.selectCompany(lastCompany, true);
    }
    else if (this.assignments && this.assignments.length > 0)
    {
      await this.selectCompany(this.assignments[0].company, true);
    }
    else
    {
      this.emit();
    }
  }

  getCurrentIdentity(): Identity
  {
    if (!this.user && !this.reviewer)
    {
      return undefined;
    }
    if (this.user)
    {
      this.user.timestampUtc = new Date(this.user.timestampUtc);
    }
    if (this.reviewer)
    {
      this.reviewer.timestampUtc = new Date(this.reviewer.timestampUtc);
    }
    return { user: this.user, company: this.company, role: this.role, assignments: this.assignments, reviewer: this.reviewer };
  }

  /**
   * Tests the authorized connection for the current user.
   */
  private renewLogin(): Promise<UserResponse>
  {
    // TODO 9821: Handle rejection because of mssing approval of privacy and terms
    return new Promise<UserResponse>(async (resolve, reject) =>
    {
      try
      {
        const headers = await this.getCurrentUserAuthHeaders();
        const backendUrl = this.platformInfoProvider.getBackendUrl();
        this.connectionService.httpClient.get(`${backendUrl}/api/auth/renewLogin`, {headers: headers, responseType: 'text'})
          .subscribe(response =>
          {
            const uResponse: UserResponse = JSON.parse(response);
            if (uResponse.user && !uResponse.user.userConfig)
            {
              uResponse.user.userConfig = {taskReportConfig: {disabled: false, taskReportDays: []}};
            }
            resolve(uResponse);
          },
          error =>
          {
            reject(error);
          });
      }
      catch (err)
      {
        reject(err);
      }
    });
  }

  testAnonymous(): Promise<boolean>
  {
    return new Promise<boolean>(async(res, rej) =>
    {
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.post<BackendInfo>(`${backendUrl}/api/test/extensionconnectiontest`, {})
        .subscribe(response => res(true), error => res(false));
    });
  }

  signInReviewer(id: string): Promise<UserResponse>
  {
    return new Promise<UserResponse>(async (resolve, reject) =>
    {
      const signin: SignIn = {username: undefined, password: undefined, reviewerId: id};
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.post<UserResponse>(`${backendUrl}/api/auth/signin`, signin).subscribe(async uResponse =>
      {
        await this.signOut();
        const reviewer = uResponse.reviewer;
        this.reviewer = reviewer;
        this.company = await this.getFullCompany(reviewer.companyId);
        this.user = undefined;
        this.role = undefined;
        this.assignments = undefined;
        await this.userProvider.storeReviewer(reviewer);
        resolve(uResponse);
      }, error =>
      {
        this.reviewer = undefined;
        this.user = undefined;
        this.company = undefined;
        this.role = CompanyRole.none;
        this.assignments = [];
        reject(error);
      });
    });
  }

  async loginDemoUser()
  {
    const demoLogin = await this.get<DemoLogin>(`/api/auth/logindemouser`);
    demoLogin.user.isDemoUser = true;
    this.handleLogin(demoLogin.user, null, demoLogin.companyId);
  }

  isDemoUser(): boolean
  {
    if (this.user && this.user.isDemoUser === true)
    {
      return true;
    }
    return false;
  }

  async login(username: string, password: string, companyId?: string): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      const signin: SignIn = {
        username: username,
        password: password,
        reviewerId: undefined,
        userDeviceId: this.userDevice?.id,
        frontendName: this.frontendInfo?.frontendName
      };
      this.connectionService.httpClient.post<UserResponse>(`${this.platformInfoProvider.getBackendUrl()}/api/auth/login`, signin).subscribe(
        async response =>
        {
          await this.handleLogin(response.user, undefined, companyId, response.userDevice);
          if (response.error && response.error === MISSING_APPROVAL_ERROR)
          {
            this.usersApprovalMissing.emit();
          }
          resolve();
        },
        async error =>
        {
          await this.handleLogin(undefined, error);
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  /**
   * Works only for SuperUsers. When finished successfully, an email was sent to the
   * registered address.
   * The email sent to the users contains a link like this: URL/resetpassword/userId/verificationToken
   * @param user The user who's password to reset
   * @
   */
  async sendPasswordResetMailSuperUser(user: User): Promise<UserVerificationResponse>
  {
    console.log('sendPasswordResetMailSuperUser');
    user.language = this.translationService.currentLanguage;
    const result = await this.authPost<UserVerificationResponse>('/api/users/sendPasswordResetMailSuperUser', user);
    console.log(result);
    if (result.error)
    {
      result.error = this.connectionService.translateServerError(result.error);
    }
    else if (result.validUntilUtc)
    {
      result.validUntilUtc = new Date(result.validUntilUtc);
    }
    return result;
  }

  /**
   * Anonymous endpoint to reset a password. When finished successfully, an email was sent to the
   * registered address.
   * The email sent to the users contains a link like this: URL/resetpassword/userId/verificationToken
   * @param user Needs the userName or the email for an existing user.
   */
  async sendPasswordResetMail(user: User): Promise<UserVerificationResponse>
  {
    console.log('sendPasswordResetMail');
    user.language = this.translationService.currentLanguage;
    const result = await this.post<UserVerificationResponse>('/api/users/sendPasswordResetMail', user);
    if (result.error)
    {
      result.error = this.connectionService.translateServerError(result.error);
    }
    else if (result.validUntilUtc)
    {
      result.validUntilUtc = new Date(result.validUntilUtc);
    }
    return result;
  }

  /**
   * To reset the password using the userId, verificationToken from the email/url, containing a new password
   * @param signIn If you also want to signIn directly as that user
   * @returns An object containig the current user or an error (if the password was invalid)
   * @throws Error if userId and/or token are invalid
   */
  async resetPasswordWithToken(userId: string, verificationToken: string, newPassword: string, signIn: boolean): Promise<UserResponse>
  {
    const params: [string, string][] = [
      ['userId', userId],
      ['verificationToken', verificationToken],
      ['newPassword', newPassword],
      ['signIn', `${signIn}`]
    ];
    const response = await this.get<UserResponse>('/api/users/resetPasswordWithToken', params);
    if (response.error)
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    if (signIn)
    {
      this.handleLogin(response.user, new Error(response.error));
    }
    return response;
  }

  /**
   * To close all sessions using the userId, verificationToken from the email/url,
   * @param userId The user id to close all sessions
   * @param verificationToken The token to verify the user
   * @returns An object containig the current user or an error (if the token was invalid)
   */
  async closeSessionsWithToken(userId: string, verificationToken: string): Promise<UserResponse>
  {
    const params: [string, string][] = [
      ['userId', userId],
      ['verificationToken', verificationToken]
    ];
    const response = await this.get<UserResponse>('/api/users/closeSessionsWithToken', params)
    if (response.error)
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    return response;
  }

  async handleLogin(user: User, error: Error, companyId?: string, userDevice?: UserDevice)
  {
    await this.waitForInit();
    if (user)
    {
      console.log(`signed in: ${JSON.stringify(user)}`);
      if (!user.userConfig)
      {
        user.userConfig = {taskReportConfig: {disabled: false, taskReportDays: []}};
      }
      await this.userProvider.storeUser(user);
      await this.userProvider.storeReviewer(undefined);
      await this.userProvider.storeUserDevice(userDevice);
      this.reviewer = undefined;
      this.user = user;
      this.userDevice = userDevice;
      this.company = undefined;
      this.role = CompanyRole.none;
      const assignments = await this.getUserAssignments();
      if (this.frontendInfo.frontendName === 'ILST')
      {
        this.assignments = _.filter(assignments, a => a.role > CompanyRole.stakeholder);
      }
      else
      {
        this.assignments = _.filter(assignments, a => a.role > CompanyRole.none);
      }
      if (companyId)
      {
        const c = _.find(this.assignments, a => a.company.id === companyId);
        if (c)
        {
          await this.selectCompany(c.company, true);
        }
      }
      else
      {
        const lastCompId = await this.userProvider.getLastCompanyId();
        await this.chooseDefaultCompany(lastCompId);
        this.emit();
      }
      this.logHubspotSignInEvent.emit({ user: user, companyName: this.company ? this.company.name: ''});
    }
    else
    {
      console.log('Login error');
      console.log(error);
      this.user = undefined;
      this.reviewer = undefined;
      this.userDevice = undefined;
      await this.userProvider.storeUser(undefined);
      await this.userProvider.storeReviewer(undefined);
      await this.userProvider.storeUserDevice(undefined);
      this.company = undefined;
      this.role = CompanyRole.none;
      this.assignments = [];
      this.emit();
    }
  }

  /**
   * Register new user with 'pending' flag and send a mail with the link to verify
   * If no errors in the userResponse, tell the user to check the mail account
   */
  async registerUserWithVerification(userData: User): Promise<UserResponse>
  {
    const response = await this.post<UserResponse>('/api/users/registerUserWithVerification', userData);
    if (response.error)
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    return response;
  }

  /**
   * Register new user (only with the email set) with 'pending' flag and send a mail with the link to verify
   * If no errors in the userResponse, tell the user to check the mail account
   */
  async registerUserWithEmail(email: string, language: 'en' |'es' | 'pt' = 'en'): Promise<UserResponse>
  {
    const userData: User = {email: email, language: language};
    const response = await this.post<UserResponse>('/api/users/registerUserWithEmail', userData);
    if (response.error)
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    return response;
  }

  /**
   * Resend verification token, user must have 'pending'=true
   * If no errors in the UserVerificationResponse, tell the user to check the mail account
   */
  async resendVerificationToken(userId: string): Promise<UserVerificationResponse>
  {
    const params: [string, string][] = [
      ['userId', userId]
    ];
    const result = await this.get<UserVerificationResponse>('/api/users/resendVerificationToken', params);
    if (result.error)
    {
      result.error = this.connectionService.translateServerError(result.error);
    }
    else if (result.validUntilUtc)
    {
      result.validUntilUtc = new Date(result.validUntilUtc);
    }
    return result;
  }

  /**
   * To complete an account registration.
   * If user was not invitied to a specific workspace, the user
   * could configure his/her workspace (name and kind in the profile component)
   */
  async completeRegistration(accountCompletion: AccountCompletion): Promise<UserResponse>
  {
    const response = await this.post<UserResponse>('/api/users/completeRegistration', accountCompletion);
    if (!response.error)
    {
      await this.handleLogin(response.user, undefined);
    }
    else
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
      await this.handleLogin(undefined, new Error(response.error));
    }
    return response;
  }

  /**
   * To verify an account for changing password or email.
   * Performs a login (generates access token)
   * Must have a valid token AND password AND you can't change the email!!!
   * Returns the complete user account OR errors (including verification expired)
   */
  async verifyAccount(userData: User): Promise<UserResponse>
  {
    const response = await this.post<UserResponse>('/api/users/verifyAccount', userData);
    if (!response.error)
    {
      this.handleLogin(response.user, undefined);
    }
    else
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
      this.handleLogin(undefined, new Error(response.error));
    }
    return response;
  }

  /**
   * To change email of an existent account.
   * Must have a valid token AND email
   * Returns the complete user account OR errors (including verification expired)
   */
  async changeEmail(userData: User): Promise<UserResponse>
  {
    const response = await this.authPost<UserResponse>('/api/users/changeEmail', userData);
    if (response.user)
    {
      await this.userProvider.storeUser(response.user);
      this.user = response.user;
      this.emit();
    }
    else
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    return response;
  }

  async updateProfile(userData: User): Promise<UserResponse>
  {
    const passwordChanged =
      userData.oldPassword && userData.oldPassword.length > 0 &&
      userData.password && userData.password.length > 0 &&
      userData.repeatPassword && userData.repeatPassword.length > 0;
    const response = await this.authPost<UserResponse>(`/api/users/updateprofile`, userData);
    if (response.user !== undefined && response.user != null)
    {
      await this.userProvider.storeUser(response.user);
      this.user = response.user;
      this.emit();
      if (passwordChanged)
      {
        this.signOut();
      }
    }
    else
    {
      response.originalError = response.error;
      response.error = this.connectionService.translateServerError(response.error);
    }
    return response;
  }

  async storeLanguage(language: 'en' | 'es' | 'pt')
  {
    console.log('###TRANS storeLanguage: ' + language);
    const params: [string, string][] = [
      ['language', language]
    ];
    const user = await this.authGet<User>('/api/users/changelanguage', params);
    this.user.language = language;
    await this.userProvider.storeUser(this.user);
    console.log('###TRANS storeLanguage done: ' + this.user.language);
    return user;
  }

  async signOut(): Promise<boolean>
  {
    this.user = await this.userProvider.getCurrentUser();
    this.userDevice = await this.userProvider.getUserDevice();
    if (this.userDevice && this.user)
    {
      const signOutData = {deviceId: (await this.userProvider.getUserDevice()).id};
      await this.userProvider.storeUserDevice(undefined);
      await this.userProvider.storeUser(undefined);
      await this.authPost<void>('/api/auth/logout', signOutData);
    }
    await this.userProvider.storeUser(undefined);
    await this.userProvider.storeReviewer(undefined);
    await this.userProvider.storeUserDevice(undefined);
    this.user = undefined;
    this.reviewer = undefined;
    this.userDevice = undefined;
    this.company = undefined;
    this.role = CompanyRole.none;
    this.assignments = [];
    this.emit();
    return true;
  }

  async getUserAssignments(): Promise<UserAssignment[]>
  {
    const assignments = await this.authGet<UserAssignment[]>('/api/users/getuserassignments');
    return assignments;
  }

  hasAssignment(company: Company): boolean
  {
    return _.some(this.assignments, a => a.role > CompanyRole.none && a.companyId === company.id);
  }

  async selectCompany(company: Company, registerEvent?: boolean)
  {
    if (!this.user || !this.assignments || this.assignments.length === 0)
    {
      throw Error('No user or assignments available, can\'t select company');
    }
    let c: Company = null;
    let role = CompanyRole.none;
    if (company)
    {
      const assignment = _.find(this.assignments, assgnmt => assgnmt.companyId === company.id && assgnmt.role > CompanyRole.none);
      if (!assignment)
      {
        throw Error('Current user has no assignment for this company');
      }
      role = assignment.role;
      c = await this.getFullCompany(company.id, registerEvent);
    }
    this.company = c;
    this.role = role;
    await this.userProvider.storeCompanyid(this.company ? this.company.id : null);
    this.emit();
  }

  async refreshAssignments(toSelect?: Company)
  {
    const assignments = await this.getUserAssignments();
    this.assignments = _.filter(assignments, a => a.role > CompanyRole.none);
    if (toSelect)
    {
      const assignment = _.find(this.assignments, assgnmt => assgnmt.companyId === toSelect.id && assgnmt.role > CompanyRole.none);
      if (assignment)
      {
        this.company = await this.getFullCompany(toSelect.id);
        this.role = assignment.role;
      }
      else
      {
        this.company = undefined;
        this.role = CompanyRole.none;
      }
      this.emit();
    }
    else if (this.company)
    {
      const assignment = _.find(this.assignments, assgnmt => assgnmt.companyId === this.company.id && assgnmt.role > CompanyRole.none);
      if (assignment)
      {
        this.company = await this.getFullCompany(this.company.id);
        this.role = assignment.role;
      }
      else
      {
        this.company = undefined;
        this.role = CompanyRole.none;
      }
      this.emit();
    }
    else
    {
      const lastCompId = await this.userProvider.getLastCompanyId();
      this.chooseDefaultCompany(lastCompId);
    }
  }

  async getFullCompany(companyId: string, registerEvent?: boolean): Promise<Company>
  {
    const params: [string, string][] = [['companyId', companyId]];

    //const result = await this.authGet<Company>('/api/configuration/getfullcompany', params);
    //this.clsValuesPromise = null;
    const result = await this.authGet<Company>('/api/v2/companies/getCompany', params);
    if (registerEvent)
    {
      try
      {
        const postData: RegisterCompanyLoginRequest = {companyId: companyId};
        await this.authPost('/api/v2/users/registerCompanyLogin', postData);
      }
      catch(err)
      {
        console.log('Error registering company login event', err);
      }
    }

    this.setStorageUsage(result);

    if (this.frontendInfo.frontendName === 'ILST')
    {
      await this.setClassValues(result);
      this.clsValuesPromise = null;
    }
    else
    {
      this.clsValuesPromise = this.setClassValues(result);
    }


    if (result && !result.configuration)
    {
      result.configuration = {};
    }
    if (result && !result.configuration.taskReportConfig)
    {
      result.configuration.taskReportConfig = {taskReportDays: []};
    }
    if (result && !result.configuration?.internalUserAllowedDownloadFormat)
    {
      result.configuration.internalUserAllowedDownloadFormat = this.allowedDownloadDocumentFormat();
    }
    if (result && !result.configuration?.externalReviewerAllowedDownloadFormat)
    {
      result.configuration.externalReviewerAllowedDownloadFormat = this.allowedDownloadDocumentFormat();
    }
    result.timestampUtc = new Date(result.timestampUtc);
    result.isStorageUsageLimitReached =
      result.subscriptionPlan?.sizeTotalLimitGb > 0 &&
      result.companyStorageUsage?.totalStorageUsage / GIGABYTE >= result.subscriptionPlan?.sizeTotalLimitGb;
    return result;
  }

  private async setStorageUsage(company: Company)
  {
    const params: [string, string][] = [['companyId', company.id]];
    company.companyStorageUsage = await this.authGet<CompanyStorageUsage>('/api/v2/companies/getStorageUsage', params);
  }

  private async setClassValues(company: Company)
  {
    try
    {
      const params: [string, string][] = [['companyId', company.id]];

      const classValues: ClassValue[] = [];
      const classValuesResponse = await this.authGet<PagedResponse<ClassValue>>('/api/v2/companies/getClassValues', params);
      classValues.push(...classValuesResponse.result);
      if (classValuesResponse.pageCount > 1)
      {
        for (let i = 2; i <= classValuesResponse.pageCount; i++)
        {
          const pageParams: [string, string][] = [['companyId', company.id], ['page', i.toString()]];
          const pageResponse = await this.authGet<PagedResponse<ClassValue>>('/api/v2/companies/getClassValues', pageParams);
          classValues.push(...pageResponse.result);
        }
      }

      company.documentTypes.forEach(dt =>
      {
        if (dt.classes && dt.classes.length > 0)
        {
          const plainClassList = this.getPlainClassList(dt.classes);
          plainClassList.forEach(c => c.classValues = classValues.filter(cv => cv.classId === c.id))
        }

      });

      const plainClassList2 = this.getPlainClassList(company.classes);
      plainClassList2.forEach(c => c.classValues = classValues.filter(cv => cv.classId === c.id));
    }
    catch(err)
    {
      console.log('Error getting class values', err);
    }
    finally
    {
      this.clsValuesPromise = null;
    }

  }

  private getPlainClassList (clss: Class[]): Class[]
  {
    const result: Class[] = [];
    for (const cls of clss)
    {
      result.push(cls);
      if (cls.children)
      {
        const plainChilds = this.getPlainClassList(cls.children);
        result.push(...plainChilds);
      }
    }
    return result;
  }

  async acceptLegals(): Promise<void>
  {
    await this.authPost<void>('/api/auth/acceptlegals', undefined);
  }

  /**
   * Performs a get call to the api, taking care of the token headers
   * @param relUrl
   * @param params
   */
  async authGet<T>(relUrl: string, params?: [string, string][], blob?: boolean): Promise<T>
  {
    const headers = await this.getCurrentUserAuthHeaders();
    let httpParams = new HttpParams();
    if (params)
    {
      params.forEach(p =>
      {
        if (p[0] && p[1])
        {
          httpParams = httpParams.append(p[0], p[1]);
        }
      });
    }

    const options = blob ?
      {headers: headers, params: httpParams, responseType: 'blob' as 'json'} :
      {headers: headers, params: httpParams};
    return new Promise<any>((resolve, reject) =>
    {
      const baseUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.get<T>(`${baseUrl}${relUrl}`, options)
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  /**
   * Performs a get call to the api, taking care of the token headers
   * @param relUrl
   * @param params
   */
  async authGetObjectParam<T>(relUrl: string, obj?: object, blob?: boolean): Promise<T>
  {
    const headers = await this.getCurrentUserAuthHeaders();
    let httpParams = new HttpParams();
    if (obj)
    {
      httpParams = this.convertObjectToParams(obj);
    }

    const options = blob ?
      {headers: headers, params: httpParams, responseType: 'blob' as 'json'} :
      {headers: headers, params: httpParams};
    return new Promise<any>((resolve, reject) =>
    {
      const baseUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.get<T>(`${baseUrl}${relUrl}`, options)
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  private convertObjectToParams(obj: object): HttpParams
  {
    let params = new HttpParams();
    for (const key in obj)
    {
      if (obj.hasOwnProperty(key))
      {
        const value = (obj[key] !== null && obj[key] !== undefined) ? obj[key].toString() : '';
        if (value)
        {
          params = params.set(key, value);
        }
      }
    }
    return params;
  }

  /**
   * Performs a get call to the api, for anonymous endpoints
   * @param relUrl
   * @param params
   */
  async get<T>(relUrl: string, params?: [string, string][]): Promise<T>
  {
    let httpParams = new HttpParams();
    if (params)
    {
      params.forEach(p =>
      {
        if (p[0] && p[1])
        {
          httpParams = httpParams.append(p[0], p[1]);
        }
      });
    }
    return new Promise<any>((resolve, reject) =>
    {
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.get<T>(`${backendUrl}${relUrl}`, {params: httpParams})
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  // We need the id or the url
  longGet(relUrl: string, useAuthHeader: boolean, params?: [string, string][],
    progressCallback?: (progress: Progress) => void): Promise<any>
  {
    return new Promise<any>(async (resolve, reject) =>
    {
      // If we download through the same webapp, we need to use the auth token
      // For azure blob storage access, this auth token would lead to errors
      let headers: any;
      let httpParams: HttpParams;
      if (params)
      {
        httpParams = new HttpParams();
        params.forEach(p =>
        {
          if (p[0] && p[1])
          {
            httpParams = httpParams.append(p[0], p[1]);
          }
        });
      }
      if (useAuthHeader)
      {
        headers = await this.getCurrentUserAuthHeaders();
      }
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      const downloadReq = new HttpRequest('GET', `${backendUrl}${relUrl}`, undefined, {
        reportProgress: true,
        responseType: 'arraybuffer',
        headers: headers,
        params: httpParams
      });
      this.longRequest<void>(downloadReq, resolve, reject, progressCallback);
    });
  }

  /**
   * Performs a delete call to the api, taking care of the token headers
   * @param relUrl
   * @param params
   */
  async authDelete<T>(relUrl: string, params?: [string, string][]): Promise<T>
  {
    const headers = await this.getCurrentUserAuthHeaders();
    let httpParams = new HttpParams();
    if (params)
    {
      params.forEach(p =>
      {
        if (p[0] && p[1])
        {
          httpParams = httpParams.append(p[0], p[1]);
        }
      });
    }
    return new Promise<any>((resolve, reject) =>
    {
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.delete<T>(`${backendUrl}${relUrl}`, {headers: headers, params: httpParams})
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  /**
   * Performs a post call to the api, taking care of the token headers
   * @param relUrl
   * @param params
   */
  async authPost<T>(relUrl: string, body: any, blob: boolean = false): Promise<T>
  {
    const headers = await this.getCurrentUserAuthHeaders();
    return new Promise<any>((resolve, reject) =>
    {
      const options = blob ? {headers: headers, responseType: 'blob' as 'json'} : {headers: headers};
      this.connectionService.httpClient.post<T>(`${this.platformInfoProvider.getBackendUrl()}${relUrl}`, body, options)
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject, this.getCurrentIdentity(), JSON.stringify(body));
        });
    });
  }

  /**
   * Performs a post call to the api, for anonymous endpoints
   * @param relUrl
   * @param params
   */
  async post<T>(relUrl: string, body: any): Promise<T>
  {
    return new Promise<any>((resolve, reject) =>
    {
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      this.connectionService.httpClient.post<T>(`${backendUrl}${relUrl}`, body)
        .subscribe(async response =>
        {
          resolve(response);
        }, error =>
        {
          this.connectionService.handleReject(error, reject, this.getCurrentIdentity(), JSON.stringify(body));
          // reject(error);
        });
    });
  }

  async longPost<T>(relUrl: string, body: any, progressCallback?: (progress: Progress) => void, blob: boolean = false): Promise<T>
  {
    return new Promise<T>(async (resolve, reject) =>
    {
      let headers = await this.getCurrentUserAuthHeaders();
      // Bypass the service worker entirely and let the browser handle the request instead
      // the service worker does not handle well the progressCallback
      headers = headers.append('ngsw-bypass', 'true');
      const options = blob ?
        {headers: headers, reportProgress: true, responseType: 'blob' as 'json'} :
        {headers: headers, reportProgress: true};
      const backendUrl = this.platformInfoProvider.getBackendUrl();
      const uploadReq = new HttpRequest('POST', `${backendUrl}${relUrl}`, body, options);
      this.longRequest<T>(uploadReq, resolve, reject, progressCallback);
    });
  }

  async uploadToBlobStorageSasUrl(url: string, file: File): Promise<void>
  {
    return new Promise<void>(async (resolve, reject) =>
    {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const headers = new HttpHeaders({'x-ms-blob-type': 'BlockBlob'});
      this.connectionService.httpClient.put(url, file, {headers})
        .subscribe(() =>
        {
          resolve();
        }, error =>
        {
          this.connectionService.handleReject(error, reject);
        });
    });
  }

  longRequest<T>(
    request: HttpRequest<any>,
    resolve: (value?: T | PromiseLike<T>) => void,
    reject: (reason?: any) => void,
    progressCallback?: (progress: Progress) => void)
  {
    let oldTime = 0;
    let oldLoad = 0;
    const progress: Progress =
    {
      loadedKB: 0,
      loadedMB: 0,
      totalKB: 0,
      totalMB: 0,
      speedKBps: 0,
      speedMBps: 0,
      percentage: 0,
      cancelAction: () =>
      {
        subscription.unsubscribe();
        const err = new Error();
        (<any>err).error = 'Cancelled by user';
        reject(err);
      }
    };
    const subscription = this.connectionService.httpClient.request(request).subscribe(event =>
    {
      if ((event.type === HttpEventType.UploadProgress || event.type === HttpEventType.DownloadProgress)
            && progressCallback !== undefined)
      {
        const newTime = new Date().getTime();
        const newLoad = event.loaded;
        const miliseconds = newTime - oldTime;
        const bytes = newLoad - oldLoad;
        progress.loadedKB = +(newLoad / KILOBYTE).toFixed(2);
        progress.loadedMB = +(newLoad / MEGABYTE).toFixed(2);
        progress.totalKB = +(event.total / KILOBYTE).toFixed(2);
        progress.totalMB = +(event.total / MEGABYTE).toFixed(2);
        progress.speedKBps = +(((bytes) / KILOBYTE) / (miliseconds / 1000)).toFixed(2);
        progress.speedMBps = +(((bytes) / MEGABYTE) / (miliseconds / 1000)).toFixed(2);
        progress.percentage = Math.round(100 * event.loaded / event.total);

        progressCallback(progress);

        if (miliseconds > 2000)
        {
          oldTime = newTime;
          oldLoad = newLoad;
        }
      }
      else
      {
        if (event.type === HttpEventType.Response)
        {
          if (request.method === 'GET')
          {
            const buf = <ArrayBuffer>(event.body);
            console.log(`Download ready, received ${buf.byteLength} bytes`);
            const intArray = new Int8Array(buf);
            const newbuf = new Buffer(<any>intArray, 'base64');
            resolve(<any>newbuf);
          }
          else
          {
            console.log(`longRequest done`);
            const document = (<HttpResponse<T>>event).body;
            resolve(document);
          }
        }
      }
    },
    error =>
    {
      this.connectionService.handleReject(error, reject);
    },
    () => console.log('longRequest complete'));
  }

  /**
   * Creates the header object needed for authorized http calls.
   */
  async getCurrentUserAuthHeaders(): Promise<HttpHeaders>
  {
    let token: string;
    this.user = await this.userProvider.getCurrentUser();
    if (this.user && this.user.token)
    {
      // Check if the token is expired and try to refresh it
      if(this.isTokenExpired(this.user.token))
      {
        await this.refresh();
      }
      token = this.user.token;
    }
    else if (this.reviewer && this.reviewer.token)
    {
      token = this.reviewer.token;
    }
    else
    {
      this.signOut();
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + token });
    return headers;
  }

  /**
   * Refreshes the authentication token by making a request to the backend.
   *
   * This method sends a POST request to the `/api/auth/refresh` endpoint with the user's device information.
   * Upon a successful response, it updates the user's token and device information, and stores them using the user provider.
   * If the request fails, it handles the error using the connection service.
   *
   * @returns {Promise<void>} A promise that resolves when the token and device information are successfully updated and stored.
   */
  async refresh(): Promise<void>
  {
    // If the refresh token is expired
    // emit the refreshTokenExpired event and reject the promise
    this.userDevice = await this.userProvider.getUserDevice();
    if (!this.userDevice || new Date(this.userDevice.expiresOnUtc) < new Date())
    {
      this.refreshPromise = null;
      this.initPromise = null;
      this.userDevice = undefined;
      this.refreshTokenExpired.emit();
      return Promise.resolve();
    }
    // If there isn't another refresh in progress call the refresh endpoint to get a new token
    if (this.refreshPromise)
    {
      return this.refreshPromise;
    }
    this.refreshPromise = new Promise((resolve, reject) =>
    {
      this.connectionService.httpClient
        .post<UserResponse>(`${this.platformInfoProvider.getBackendUrl()}/api/auth/refresh`, this.userDevice)
        .subscribe(
          async response =>
          {
            this.user.token = response.user.token;
            await this.userProvider.storeUser(this.user);
            this.userDevice = response.userDevice;
            await this.userProvider.storeUserDevice(this.userDevice);
            this.refreshPromise = null;
            resolve();
          },
          async error =>
          {
            this.refreshPromise = null;
            this.initPromise = null;
            this.userDevice = undefined;
            this.refreshTokenExpired.emit();
            reject(error);
          });
    });
    return this.refreshPromise;
  }

  /**
   * Extracts the expiration date from a JWT token.
   *
   * @param token - The JWT token from which to extract the expiration date.
   * @returns The expiration date of the token as a Date object.
   */
  getTokenExpirationDate(token: string): Date
  {
    const payloadBase64 = token.split('.')[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const expirationDate = new Date(decodedPayload.exp * 1000);
    return expirationDate;
  }

  /**
   * Checks if the JWT token is expired.
   *
   * @param token - The JWT token to check.
   * @returns True if the token is expired, false otherwise.
   */
  isTokenExpired(token: string): boolean
  {
    const expirationDate = this.getTokenExpirationDate(token);
    return expirationDate < new Date();
  }

  private emit()
  {
    let targetLanguage = this.getStoredLanguage();
    if (!targetLanguage)
    {
      targetLanguage = this.translationService.browserLanguage;
    }
    if (targetLanguage !== this.translationService.currentLanguage)
    {
      this.translationService.changeLanguage(targetLanguage);
    }
    const identity = this.getCurrentIdentity();
    if(identity || this.frontendInfo.frontendName === 'ILST')
    {
      this.identityChanged.emit(identity);
    }
    else
    {
      this.refreshTokenExpired.emit();
    }
  }

  webDownload(url: string)
  {
    const link = document.createElement('a');
    link.attributes['download'] = undefined;
    link.href = url;
    link.click();
  }

  private async languageChanged()
  {
    try
    {
      const currentLang = this.translationService.currentLanguage;
      const userLang = this.getStoredLanguage();
      if (userLang !== currentLang && this.user)
      {
        await this.storeLanguage(currentLang);
      }
    }
    catch (err)
    {
      console.log('Error storing language');
      console.log(err);
    }
  }

  private getStoredLanguage(): 'en' | 'es' | 'pt' | undefined
  {
    let result;
    if (this.user)
    {
      result = this.user.language;
    }
    else if (this.company)
    {
      result = this.company.language;
    }
    return result;
  }
  private allowedDownloadDocumentFormat()
  {
    const allowedDownloadFormat: AllowedDownloadFormat =
    {
      allowPng: true,
      allowAi: true,
      allowAiOutline: true,
      allowJpg: true,
      allowPdf: true,
      allowPdfSoft: true,
      allowZip: true
    };
    return allowedDownloadFormat;
  }


  /* Method should check the following requirements:
    - Password is too Short (8 Chars min)
    - Password have no spaces
    - Password have at least one upper case
    - Password have at least one lower case
    - Password have at least one number
    - Password have at least one Special character
    */
  public verifyPasswordStatus(password: string): PasswordValidStatus
  {
    const passwordStatus: PasswordValidStatus = {};
    passwordStatus.validLength = password.length >= 8;
    passwordStatus.validNoSpaces = password.indexOf(' ') === -1;
    passwordStatus.validCapitalLetter = /[A-Z]/.test(password);
    passwordStatus.validLowerCase = /[a-z]/.test(password);
    passwordStatus.validNumber = /\d/.test(password);
    passwordStatus.validSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return passwordStatus;
  }
}
