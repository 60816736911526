export interface UserAssignmentsFilter
{
    page?: number;
    orderBy?: UserAssignmentFilterOrderBy;
    reverseOrder?: boolean;
    companyId?: string;
    assigmentUserId?: string;
    text?: string;
}

export enum UserAssignmentFilterOrderBy
{
    Username,
    Fullname,
    CompanyName,
    Email,
    Role
}
